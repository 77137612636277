<template>
  <div class="club">
    <!-- HERO -->
    <div class="club-hero">
      <div class="container">
        <div class="club-hero__descr">
          <img
            src="@/assets/img/club-page/logo.png"
            class="club-hero__logo"
            alt="logo"
            width="154"
            height="71"
            data-aos-duration="800"
            data-aos="fade-down"
          />
          <div
            class="club-hero__slogan"
            data-aos-duration="1000"
            data-aos="fade-right"
          >
            {{ $t("BuyWithProfit") }}
          </div>
          <div
            class="club-hero__info"
            data-aos-duration="1000"
            data-aos="fade-right"
          >
            {{ $t("LoyaltyProgramMobileApplication") }}
          </div>
        </div>
      </div>
      <img
        v-if="$i18n.locale === 'ru'"
        src="@/assets/img/club-page/main.png"
        alt="image"
        class="club-hero__img"
        data-aos="zoom-in"
        data-aos-duration="1000"
      />
      <img
        v-else
        src="@/assets/img/club-page/main-kz.png"
        alt="image"
        class="club-hero__img"
        data-aos="zoom-in"
        data-aos-duration="1000"
      />
    </div>

    <div class="container">
      <!-- GRID -->
      <div class="club-grid">
        <img
          v-if="$i18n.locale === 'ru'"
          src="@/assets/img/club-page/aside.png"
          alt="image"
          class="club-grid__img"
          data-aos="fade-right"
          data-aos-duration="1000"
        />
        <img
          v-else
          src="@/assets/img/club-page/asidekz.png"
          alt="image"
          class="club-grid__img"
          data-aos="fade-right"
          data-aos-duration="1000"
        />
        <!-- Opprotunities -->
        <div class="club-opportunities">
          <h2
            class="club-opportunities__title big-title"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            {{ $t("MoreOpportunitiesTitle") }}
          </h2>
          <p
            class="club-opportunities__descr"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            {{ $t("MoreOpportunitiesText") }}
          </p>
        </div>
        <!-- Services -->
        <div class="club-services">
          <h2
            class="club-services__title big-title"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            {{ $t("applicationServicesTitle") }}
          </h2>

          <!-- Services ITEMS -->
          <div class="club-services__items">
            <div
              class="club-services__item"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img
                src="@/assets/img/club-page/service-1.svg"
                alt="icon"
                class="club-services__icon"
                width="61"
                height="61"
              />
              <div class="club-services__info">
                <h3 class="club-services__subtitle">
                  {{ $t("BonusesTitle") }}
                </h3>
                <div class="club-services__descr">
                  {{ $t("BonusesText") }}
                </div>
              </div>
            </div>

            <div
              class="club-services__item"
              data-aos="fade-right"
              data-aos-duration="1300"
            >
              <img
                src="@/assets/img/club-page/service-2.svg"
                alt="icon"
                class="club-services__icon"
                width="61"
                height="61"
              />
              <div class="club-services__info">
                <h3 class="club-services__subtitle">
                  {{ $t("ClubsTitle") }}
                </h3>
                <div class="club-services__descr">
                  {{ $t("ClubsText") }}
                </div>
              </div>
            </div>

            <div
              class="club-services__item"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <!-- <icon-services /> -->
              <img
                src="@/assets/img/club-page/service-3.svg"
                alt="icon"
                class="club-services__icon"
                width="61"
                height="61"
              />
              <div class="club-services__info">
                <h3 class="club-services__subtitle">
                  {{ $t("MyPurchasesTitle") }}
                </h3>
                <div class="club-services__descr">
                  {{ $t("MyPurchasesText") }}
                </div>
              </div>
            </div>

            <div
              class="club-services__item"
              data-aos="fade-right"
              data-aos-duration="1700"
            >
              <img
                src="@/assets/img/club-page/service-4.svg"
                alt="icon"
                class="club-services__icon"
                width="61"
                height="61"
              />
              <div class="club-services__info">
                <h3 class="club-services__subtitle">
                  {{ $t("PromotionsTitle") }}
                </h3>
                <div class="club-services__descr">
                  {{ $t("PromotionsText") }}
                </div>
              </div>
            </div>

            <div
              class="club-services__item"
              data-aos="fade-right"
              data-aos-duration="1700"
            >
              <img
                src="@/assets/img/club-page/service-5.svg"
                alt="icon"
                class="club-services__icon"
                width="61"
                height="61"
              />
              <div class="club-services__info">
                <h3 class="club-services__subtitle">
                  {{ $t("PricecheckerTitle") }}
                </h3>
                <div class="club-services__descr">
                  {{ $t("PricecheckerText") }}
                </div>
              </div>
            </div>

            <div
              class="club-services__item"
              data-aos="fade-right"
              data-aos-duration="1700"
            >
              <img
                src="@/assets/img/club-page/service-6.svg"
                alt="icon"
                class="club-services__icon"
                width="61"
                height="61"
              />
              <div class="club-services__info">
                <h3 class="club-services__subtitle">
                  {{ $t("StoreAddressesTitle") }}
                </h3>
                <div class="club-services__descr">
                  {{ $t("StoreAddressesText") }}
                </div>
              </div>
            </div>

            <div
              class="club-services__item"
              data-aos="fade-right"
              data-aos-duration="1700"
            >
              <img
                src="@/assets/img/club-page/service-7.svg"
                alt="icon"
                class="club-services__icon"
                width="61"
                height="61"
              />
              <div class="club-services__info">
                <h3 class="club-services__subtitle">
                  {{ $t("CouponsTitle") }}
                </h3>
                <div class="club-services__descr">
                  {{ $t("CouponsText") }}
                </div>
              </div>
            </div>

            <div
              class="club-services__item"
              data-aos="fade-right"
              data-aos-duration="1700"
            >
              <img
                src="@/assets/img/club-page/service-8.svg"
                alt="icon"
                class="club-services__icon"
                width="61"
                height="61"
              />
              <div class="club-services__info">
                <h3 class="club-services__subtitle">
                  {{ $t("PromocodesTitle") }}
                </h3>
                <div class="club-services__descr">
                  {{ $t("PromocodesText") }}
                </div>
              </div>
            </div>
          </div>
          <!-- Services ITEMS -->
        </div>
      </div>

      <!-- BONUS -->
      <div class="club-bonus">
        <!-- Image -->
        <img
          src="@/assets/img/club-page/club.png"
          alt="image"
          class="club-bonus__img"
          width="372"
          height="420"
          data-aos="fade-right"
          data-aos-duration="1000"
        />
        <!-- Items -->
        <div class="club-bonus__info">
          <h2
            class="club-bonus__title big-title"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            {{ $t("receiveBonusesTitle") }}
          </h2>
          <div class="club-bonus__items">
            <div
              class="club-bonus__item"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img
                src="@/assets/img/club-page/bonus-1.svg"
                alt="icon"
                class="club-bonus__icon"
              />
              <div class="club-bonus__descr">
                {{ $t("makePurchases") }}
              </div>
            </div>
            <div
              class="club-bonus__item"
              data-aos="fade-right"
              data-aos-duration="1300"
            >
              <icon-bonus />
              <div class="club-bonus__descr">
                {{ $t("yourCard") }}
              </div>
            </div>
            <div
              class="club-bonus__item"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <img
                src="@/assets/img/club-page/bonus-3.svg"
                alt="icon"
                class="club-bonus__icon"
              />
              <div class="club-bonus__descr">
                {{ $t("bonusPointsTitle") }}
                <div class="club-bonus__note">
                  {{ $t("bonusPointsText") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- REGISTRATION -->
      <div class="club-registration">
        <img
          v-if="$i18n.locale === 'ru'"
          src="@/assets/img/club-page/cart2.png"
          alt="image"
          class="club-registration__img"
          data-aos="fade-right"
          data-aos-duration="1000"
          width="380"
        />
        <img
          v-else
          src="@/assets/img/club-page/cart2.png"
          alt="image"
          class="club-registration__img"
          data-aos="fade-right"
          data-aos-duration="1000"
          width="380"
        />
        <div class="club-registration__info">
          <h2
            class="club-registration__title big-title"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            {{ $t("RegisterCardTitle") }}
          </h2>
          <div
            class="club-registration__descr"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            {{ $t("RegisterCardText") }}
          </div>
        </div>
        <button class="club-registration__button" @click="showModal">
          {{ $t("registration") }}
        </button>
      </div>

      <!-- BENEFIT -->
      <div class="club-benefit">
        <div class="club-benefit__info">
          <h2
            class="club-benefit__title big-title"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            {{ $t("BuyProfitTitle") }}
          </h2>
          <div
            class="club-benefit__subtitle"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            {{ $t("BuyProfitText") }}
          </div>
          <img
            v-if="$isMobile()"
            class="club-benefit__phone"
            src="@/assets/img/club-page/club-benefit_phone.png"
            alt=""
          />
          <div class="club-benefit__download">
            <a
              :href="appStoreLink"
              class="magnum-club__btn magnum-club__button"
              target="_blank"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="@/assets/img/club-page/app-store.png"
                alt="image"
                width="154"
                height="44"
              />
            </a>
            <a
              :href="googlePlayLink"
              class="magnum-club__btn magnum-club__button"
              target="_blank"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="@/assets/img/club-page/google-play.png"
                alt="image"
                width="154"
                height="44"
              />
            </a>
            <a
              :href="appGalleryLink"
              class="magnum-club__btn magnum-club__button"
              target="_blank"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="@/assets/img/club-page/app-gallery.svg"
                alt="image"
                width="154"
                height="44"
              />
            </a>
          </div>
          <div class="club-benefit__links">
            <a
              class="club-benefit__link"
              data-aos="fade-left"
              data-aos-duration="1000"
              v-if="magnumRules"
              :href="link(magnumRules)"
              download
              >{{ $t("programRules") }}</a
            >

            <a
              class="club-benefit__link"
              data-aos="fade-left"
              data-aos-duration="1000"
              v-if="magnumQuestions"
              :href="link(magnumQuestions)"
              download
              >{{ $t("FrequentlyAskedQuestions") }}</a
            >

            <a
              class="club-benefit__link"
              data-aos="fade-left"
              data-aos-duration="1000"
              v-if="magnumOffer"
              :href="link(magnumOffer)"
              download
              >{{ $t("PublicOffer") }}</a
            >

            <a
              class="club-benefit__link"
              data-aos="fade-left"
              data-aos-duration="1000"
              v-if="magnumPolicy"
              :href="link(magnumPolicy)"
              download
              >{{ $t("PrivacyPolicy") }}</a
            >
          </div>
        </div>
        <img
          src="@/assets/img/club-page/phone.png"
          alt="image"
          class="club-benefit__img"
          data-aos="fade-left"
          data-aos-duration="1000"
        />
      </div>

      <app-modal-registration
        v-if="isVisibleRegistrationModal"
        @close="isVisibleRegistrationModal = false"
      />
      <app-modal-check
        v-if="isVisibleCheckModal"
        @close="isVisibleCheckModal = false"
      />
    </div>
  </div>
</template>

<script>
import {
  getGooglePlay,
  getAppStore,
  getAppGallery,
  getMagnumClubLink,
} from "@/api/links";
export default {
  name: "MagnumClub",
  components: {
    AppModalRegistration: () =>
      import("@/components/modal/AppModalRegistration.vue"),
    AppModalCheck: () => import("@/components/modal/AppModalRegistration.vue"),
    IconBonus: () => import("@/components/icons/IconBonus.vue"),
    // IconServices: () => import("@/components/icons/IconServices.vue"),
  },
  data() {
    return {
      isVisibleRegistrationModal: false,
      isVisibleCheckModal: false,
      googlePlayLink: null,
      appStoreLink: null,
      appGalleryLink: null,
      magnumClubLink: null,
      magnumRules: null,
      magnumQuestions: null,
      magnumPolicy: null,
      magnumOffer: null,
    };
  },
  created() {
    getGooglePlay().then((googlePlay) => {
      this.googlePlayLink = googlePlay.data.attributes.link;
    });
    getAppStore().then((appStore) => {
      this.appStoreLink = appStore.data.attributes.link;
    });
    getAppGallery().then((appGallery) => {
      this.appGalleryLink = appGallery.data.attributes.link;
    });
    getMagnumClubLink().then((magnumClubLink) => {
      this.magnumClubLink = magnumClubLink.attributes.link_to_rules;
      this.magnumRules = magnumClubLink.attributes.link_to_rules;
      this.magnumPolicy = magnumClubLink.attributes.confidencial_policy;
      this.magnumOffer = magnumClubLink.attributes.public_offer;
    });
  },
  mounted() {
    if (this.$route.params.redirect && this.$isMobile()) {
      window.location.replace(`mclub://ru/${this.$route.params.appPage}`);
      setTimeout(() => {
        window.location.replace("market://details?id=kz.magnum.app");
      }, "2500");
    }
  },
  methods: {
    showModal() {
      this.isVisibleRegistrationModal = true;
      document.querySelector("body").classList.add("no-scroll");
    },
    link(link) {
      return this.$helpers.getAbsolutePath(link);
    },
  },
};
</script>

<style lang="sass">
.magnum-club__btn + .magnum-club__btn
  margin-top: 0

@media (max-width: 575px)
  .magnum-club__btn + .magnum-club__btn
    margin-left: 0

// https://magnum-front.a-lux.dev/ru/magnumclub/main -> Главная
// https://magnum-front.a-lux.dev/ru/magnumclub/pricecheck -> Прайсчекер
// https://magnum-front.a-lux.dev/ru/magnumclub/promotions -> Акции страница
// https://magnum-front.a-lux.dev/ru/magnumclub/chances -> Розыгрыши страница
// https://magnum-front.a-lux.dev/ru/magnumclub/promotions/N -> Акция определенная
// N - int, id Акции https://magnum-front.a-lux.dev/ru/magnumclub/profile ->
// Вкладка профиль https://magnum-front.a-lux.dev/ru/magnumclub/transactioins ->
// Экран моих покупок https://magnum-front.a-lux.dev/ru/magnumclub/clubs -> Экран
// клубов https://magnum-front.a-lux.dev/ru/magnumclub/clubs/N -> Определенный клуб
// N - int, id Клуба https://magnum-front.a-lux.dev/ru/magnumclub/promocode/STRING
// -> Экран промокодов с заполненным промокодом STRING - строка промокода
// https://magnum-front.a-lux.dev/ru/magnumclub/actors -> Экран магазинов
// https://magnum-front.a-lux.dev/ru/magnumclub/actors/N -> Экран определенного
// магазина N - int, id Магазина
// https://magnum-front.a-lux.dev/ru/magnumclub/coupons -> Экран купонов
// https://magnum-front.a-lux.dev/ru/magnumclub/transactions -> Экран списка
// покупок https://magnum-front.a-lux.dev/ru/magnumclub/transactions/N -> Экран
// определенного списка покупок N - int, id Покупки
// https://magnum-front.a-lux.dev/ru/magnumclub/messages -> Экран уведомлений
// https://magnum-front.a-lux.dev/ru/magnumclub/settings -> Экран настроек
// https://magnum-front.a-lux.dev/ru/magnumclub/about -> Экран о программе
// https://magnum-front.a-lux.dev/ru/magnumclub/discounts?type=N&actor=T&sort=S ->
// Скидки с определенными параметрами (тип скидки, магазин, сортировка) N - int,
// тип скидки T - int, id магазинa S - String, строка для сортироки, вседа
// подставляется "type.order,category.order,id"
// https://magnum-front.a-lux.dev/ru/magnumclub/discounts/N -> определенный товар в
// каталоге скидок N - int, id Скидки
</style>
